import React, { useContext, useState } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"
import styled, { ThemeContext } from "styled-components"
import Layout from "../layout"
import List from "../list"
import ResponsiveSpacing from "../responsive-spacing"
import Center from "../center"
import Garnish from "../garnish"
import BottomColorNav from "../bottom-color-nav"
import Heading from "../heading"
import Img from "gatsby-image/withIEPolyfill"
import HeroArea from "./hero"
import RoughEdge from "../rough-edge"
import PromoSticker from "../promo-sticker"
import Button from "../button"
import TopBanner from "../banner"
import SEO from "../seo"
import SVG from "../svg"
import { connect } from "react-redux"
import postPath from "../../utils/post-path"

const BannerStyled = styled.div`
  background-color: ${(p) => p.theme.BGBlue};
  position: relative;

  > .inner {
    padding: 45px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;
  }

  h2 {
    text-align: center;
    color: ${(props) => props.theme.DarkBlue};
    margin: 40px 0 0 0;
    font-size: 6em;
    text-transform: uppercase;
    font-family: "interstate-compressed";
    line-height: 0.9em;
    font-weight: 800;
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.Blue};
    margin: 0px 0 40px 0;
    font-size: 2em;
    font-family: "Montserrat";
    letter-spacing: 0.15em;
  }
`

const Banner = ({ children, color }) => {
  return (
    <BannerStyled color={color}>
      <RoughEdge className="top" color={color} />
      <div className="inner">{children}</div>
      <RoughEdge className="bottom" color={color} />
    </BannerStyled>
  )
}

const SimilarStyled = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${(p) => p.theme.DarkBlue};
  width: 100%;
  max-width: 260px;
  height: 260px;
  margin: 50px auto;

  .blank {
    width: 293px;
    height: 244px;
    background: grey;
    margin-bottom: 20px;
  }

  h4 {
    color: ${(p) => p.theme.Blue};
    font-family: "Montserrat";
    font-size: 1.4em;
    text-align: center;
    margin: 0;
    min-height: 50px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }

  img {
    margin-bottom: 20px;
    width: 100%;
    max-width: 260px;
    height: 260px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Similar = (props) => {
  const { image, title, sharing } = props
  const link = postPath("/recipe/", title)
  return (
    <SimilarStyled to={link}>
      {image ? (
        <Image
          alt={title}
          fluid={sharing.image.localFile.childImageSharp.fluid}
        />
      ) : (
        <div className="blank" />
      )}
      <h4>{title}</h4>
    </SimilarStyled>
  )
}

const MoreRecipesStyled = styled.div`
  max-width: 1100px;
  margin: 40px auto 40px auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin: 0 8px;
  }
`

const MoreRecipes = (props) => {
  const { similar_recipes } = props
  return (
    <MoreRecipesStyled>
      {similar_recipes.map((r, i) => {
        const fields = r.acf
        return <Similar key={i} {...fields} title={r.title} />
      })}
    </MoreRecipesStyled>
  )
}

const HowToStyled = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 50px auto 150px auto;

  @media (max-width: 1120px) {
    margin: 50px 10px;
  }

  > h2 {
    font-size: 9.2em;
    color: ${(p) => p.fields.color_1};
    font-family: "interstate-compressed";
    line-height: 0.9em;
    margin: 40px 0 0 0;
    padding: 0;
    font-weight: 800;

    @media (max-width: 768px) {
      font-size: 5em;
    }
  }

  h3 {
    font-size: 2.4em;
    color: ${(p) => p.theme.Blue};
    margin: 40px 0 20px 0;
  }

  hr {
    max-width: 980px;
    border: none;
    height: 1px;
    background-color: ${(p) => p.theme.Grey};
    margin: 0;
  }

  .top-image {
    max-width: 980px;
    max-height: 450px;
    overflow: hidden;

    > .wave {
      z-index: 2;
      position: absolute;
      top: 100px;
      left: -40px;
    }
  }

  .actions {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .mobileSpacer {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .carton {
    height: 95px;
    width: 90px;
    margin-right: 20px;
  }

  .infoCont {
    position: relative;
  }

  .infoIcon {
    width: 26px;
    cursor: pointer;
  }

  .noButton {
    display: none;
  }

  a {
    color: ${(p) => p.theme.DarkBlue};
  }
`

const SocialStyled = styled.div`
  margin: 0;
  max-width: 530px;
  min-width: 300px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }

  span {
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 1.4em;
    display: inline-block;
    padding: 0 5px 0 0;

    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }

  .print {
    padding-left: 100px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  a,
  svg {
    color: ${(p) => p.theme.DarkBlue};
    width: 32px;
    margin-right: 20px;
  }
`

const Social = ({ sharing }) => {
  if (typeof window === "undefined") return ""
  const url = window.location.href
  const piImage =
    !!sharing.pinterest_image && sharing.pinterest_image.source_url
  const twCopy = sharing.twitter_copy
  const piDescription = sharing.pinterest_description
  const emailSubject = sharing.email_subject
  const emailContent = sharing.email_content
  const printPDF = sharing.printable_pdf
    ? sharing.printable_pdf.url.source_url
    : ""
  const fbUrl = `https://www.facebook.com/sharer.php?u=${url}`
  const twUrl = `https://twitter.com/intent/tweet?text=${twCopy}  ${url}`
  const mailUrl = `mailto:?subject=${emailSubject}&body=${emailContent} ${url}`
  const piUrl = `https://pinterest.com/pin/create/share/?url=${url}&media=${piImage}&description=${piDescription}`
  return (
    <SocialStyled>
      <span>SHARE</span>
      <a
        href={fbUrl}
        aria-label={"Find us on Facebook"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVG name="FB" />
      </a>
      <a
        href={twUrl}
        aria-label={"Find us on Twitter"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVG name="TW" />
      </a>
      <a
        href={piUrl}
        aria-label={"Find us on Pintrest"}
        target="_blank"
        rel="noopener noreferrer"
        data-pin-custom="true"
      >
        <SVG name="PI" />
      </a>
      <a
        aria-label={"Share via Email"}
        href={mailUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Share via Email" src="/icons/email.svg" />
      </a>
      <span className="print">PRINT</span>
      <a
        aria-label={"Download Printable Recipe"}
        target="_blank"
        rel="noopener noreferrer"
        href={printPDF || "#"}
        onClick={(e) => {
          if (!printPDF) {
            window.print()
            return false
          }
        }}
      >
        <img alt="Download Printable Recipe" src="/icons/print.svg" />
      </a>
    </SocialStyled>
  )
}

const HowTo = ({ title, fields, dispatch, flavors }) => {
  const [infoHover, setInfoHover] = useState(false)
  const [nutrShown, setNutrShown] = useState(false)
  let currentListNumber = 1
  const numSoldout = flavors.reduce((a, c) => {
    if (c.acf.flavor.promotion === "sold-out") {
      return a + 1
    }
  }, 0)
  return (
    <HowToStyled fields={fields} infoHover={infoHover} nutrShown={nutrShown}>
      <div className="top-image">
        {fields.image ? (
          <Img fluid={fields.image.localFile.childImageSharp.fluid} />
        ) : (
          ""
        )}
        <SVG className="swave" name="SWave" color={fields.color_1} />
      </div>
      <h2>{title}</h2>
      <Social sharing={fields.sharing} />
      <hr />
      <h3>INGREDIENTS</h3>
      <div className="actions">
        {flavors.map((flavor, i) => {
          const { promotion } = flavor.acf.flavor
          const img =
            flavor.acf.flavor.carton_image.localFile.childImageSharp.fluid
          const image = img.src
          const { name, sku, price } = flavor.acf.flavor
          const product = {
            name,
            image,
            sku,
            price,
          }
          return (
            <Link to={postPath("/flavors/", name)} className="hvr-buzz-out">
              <PromoSticker
                promotion={promotion}
                className="smaller topLeftSmall"
              />
              <Img fluid={img} className="carton" />
            </Link>
          )
        })}
        {numSoldout === flavors.length ? (
          <Button icon="+" disabled={true}>
            ADD TO CART
          </Button>
        ) : (
          <Button
            icon="+"
            className="mobileSpacer"
            onClick={(e) => {
              flavors.map((flavor, i) => {
                if (flavor.acf.flavor.promotion === "sold-out") return
                const img =
                  flavor.acf.flavor.carton_image.localFile.childImageSharp.fluid
                const image = img.src
                const { sku, name, price } = flavor.acf.flavor

                const product = {
                  name,
                  image,
                  sku,
                  price,
                }
                dispatch({ type: "ADD_TO_PACK", product })
              })
            }}
          >
            ADD TO PACK
          </Button>
        )}
      </div>
      <List>
        {fields.ingredients.map((item, i) => {
          return (
            <li key={i}>
              {item.check ? (
                <SVG className="check" name="Check" />
              ) : (
                <span className="blank" />
              )}
              <span
                dangerouslySetInnerHTML={{
                  __html: item.item,
                }}
              />
            </li>
          )
        })}
      </List>
      <h3>INSTRUCTIONS</h3>
      <List>
        {fields.instructions.map((item, i) => {
          const color = ["green", "orange", "red", "blue"][Math.floor(i % 4)]
          const classes = `bullet ${color}`
          let curr = ""
          if (item.numbered) {
            curr = <span className={classes}>{currentListNumber}</span>
            currentListNumber++
          }
          return (
            <li key={i}>
              {item.numbered ? curr : <span className="blank" />}
              <span
                dangerouslySetInnerHTML={{
                  __html: item.item,
                }}
              />
            </li>
          )
        })}
      </List>
    </HowToStyled>
  )
}

const ContentStyled = styled.div``

const Content = ({ pack, dispatch, data }) => {
  const theme = useContext(ThemeContext)
  const recipeId = data.currentRecipe.edges[0].node.wordpress_id
  const recipe = data.recipes.edges
    .map((e) => e.node)
    .filter((r) => r.wordpress_id === recipeId)[0]
  const fields = recipe.acf
  const garnishes = fields.bottom_cta.garnish || []
  const flavorIds = fields.flavor.map((f) => f.wordpress_id)
  const flavors = data.flavors.edges
    .map((e) => e.node)
    .filter((f) => flavorIds.includes(f.wordpress_id))
  const g = fields.bottom_cta ? fields.bottom_cta.garnish : []
  const sr = recipe.acf.more_recipes.similar_recipes
  const srIds = sr.map((n) => n.wordpress_id)
  let similar_recipes = []
  if (sr.length > 0) {
    similar_recipes = data.recipes.edges
      .map((e) => e.node)
      .filter((r) => {
        return srIds.includes(r.wordpress_id)
      })
  }
  const seo = recipe.acf.sharing
  const { search_title } = recipe.acf

  return (
    <ContentStyled fields={fields}>
      <SEO
        title={search_title || recipe.title}
        headline={seo.headline}
        description={seo.description}
        share_comment={seo.twitter_copy}
        image={seo.image}
      />
      <HeroArea
        hero_title={fields.hero_title}
        title={recipe.name || recipe.title}
        fields={fields}
        flavors={flavors}
      />
      <TopBanner inner={fields.summary} color={fields.color_1} />
      <ResponsiveSpacing>
        <HowTo
          title={recipe.title}
          fields={fields}
          dispatch={dispatch}
          flavors={flavors}
        />
      </ResponsiveSpacing>
      <Banner color={theme.BGBlue}>
        <ResponsiveSpacing>
          <h2>{fields.bottom_cta.headline}</h2>
          <h3>{fields.bottom_cta.sub_heading}</h3>
        </ResponsiveSpacing>
        <Garnish
          garnish={garnishes[0]}
          pSpeed={-0.2}
          top={-190}
          width={150}
          rotation={-55}
          rSpeed={-0.03}
          pos={"left"}
          style={{}}
        />
        <Garnish
          garnish={garnishes[1]}
          pSpeed={-0.23}
          top={-520}
          width={150}
          rotation={100}
          rSpeed={0.05}
          pos={"right"}
          style={{}}
        />
      </Banner>
      <ResponsiveSpacing>
        <Heading margin="150px 0 0 0">
          <h2>{fields.more_recipes.headline}</h2>
          <h3>{fields.more_recipes.sub_heading}</h3>
        </Heading>
        <MoreRecipes similar_recipes={similar_recipes} />
      </ResponsiveSpacing>
      <Center margin="0 0 150px 0" mobileMargin="50px 0 50px 0">
        <Button to="/recipes">{fields.more_recipes.button_label}</Button>
      </Center>
      <BottomColorNav />
    </ContentStyled>
  )
}

const ConnectedContent = connect((state) => state)(Content)

export default (props) => {
  return (
    <Layout>
      <ConnectedContent data={props.data} context={props.pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    currentRecipe: allWordpressWpRecipes(filter: { id: { eq: $id } }) {
      edges {
        node {
          wordpress_id
        }
      }
    }
    recipes: allWordpressWpRecipes {
      edges {
        node {
          title
          wordpress_id
          acf {
            name
            name_with_html
            hero_title
            color_1
            color_2
            summary
            servings
            flavor {
              wordpress_id
              post_title
            }
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 985, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sharing {
              headline
              description
              image {
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              twitter_copy
              pinterest_title
              pinterest_description
              email_subject
              email_content
              pinterest_image {
                source_url
              }
              printable_pdf {
                url {
                  source_url
                }
              }
            }
            more_recipes {
              headline
              sub_heading
              button_label
              similar_recipes {
                wordpress_id
              }
            }
            ingredients {
              check
              item
            }
            instructions {
              numbered
              item
            }
            search_title
            bottom_cta {
              headline
              sub_heading
              garnish {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    flavors: allWordpressWpFlavors {
      edges {
        node {
          title
          wordpress_id
          acf {
            flavor {
              sku
              color_1
              color_2
              name
              name_with_html
              tags
              price
              promotion
              carton_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            nutrition_feature {
              headline
              summary
              wave_color
              color_override
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
