import React from "react"
import styled from "styled-components"

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  color: ${p => p.theme.DarkBlue};

  li {
    display: grid;
    grid-template-columns: 50px 1fr;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4em;
    padding: ${p => p.padding || "16px 0"};
  }

  img {
    margin-right: 10px;
  }

  .bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    font-weight: bold;
  }

  .blank {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .green {
    background: ${p => p.theme.Green};
  }
  .orange {
    background: ${p => p.theme.Orange};
  }
  .red {
    background: ${p => p.theme.Pink};
  }
  .blue {
    background: ${p => p.theme.LightBlue};
  }
  .check {
    color: ${p => p.theme.DarkBlue};
    width: 30px;
    margin: 0 10px 0 5px;
  }
`

const List = ({ children, padding }) => {
  return <ListStyled padding={padding}>{children}</ListStyled>
}

export default List
