import React from "react"
import styled from "styled-components"
import BreadCrumbs from "../breadcrumbs"
import PromoSticker from "../promo-sticker"
import SVG from "../svg"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import postPath from "../../utils/post-path"

const HeroAreaStyled = styled.div`
  max-width: 2560px;
  max-height: 600px;
  overflow: hidden;
  margin: 93px auto 0 auto;
  display: flex;
  background-color: ${(p) => p.bgColor || "#ddd"};

  @media (max-width: 768px) {
    max-height: 300vh;
    flex-wrap: wrap;
    flex-direction: reverse;
  }

  .background,
  .info {
    width: 50%;
    max-height: 600px;

    .gatsby-image-wrapper {
      max-height: 800px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 50px;
    min-width: 600px;
    transition: padding 600ms;
    position: relative;
    @media (max-width: 1450px) {
      min-width: 0;
      padding: 50px 50px 0 50px;
    }
    @media (max-width: 768px) {
      padding: 50px 50px 0 50px;
      margin: 0;
    }
    @media (max-width: 580px) {
      padding: 50px 10px 0 10px;
    }
  }

  .info > .inner {
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-width: 700px;
    width: 100%;
    min-height: 335px;
    @media (max-width: 1068px) {
    }
    @media (max-width: 768px) {
      height: 100%;
      margin: 8px 0;
    }
  }

  .hideMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .background {
    .gatsby-image-wrapper {
      min-height: 600px;
    }
  }

  .middle {
    display: flex;
    align-items: center;
    justify-center: center;
  }

  h1 {
    color: ${(p) => p.fgColor};
    font-size: 9.2em;
    transition: font-size 500ms;
    margin: ${(p) => (p.featured ? "30px 0 20px 0" : "30px 0 60px 0")};
    width: 100%;
    line-height: 0.9em;
    max-width: 600px;
    font-weight: 800;
    text-align: left;
    font-family: "interstate-compressed";

    @media (max-width: 1468px) {
      font-size: 6.4em;
    }

    @media (max-width: 1068px) {
      max-width: 100%;
      font-size: 5.4em;
      margin: 0 0 20px 0;
    }
  }

  .wave {
    margin-bottom: 30px;
  }

  .servings {
    display: flex;
    flex-flow: ${(p) => (p.featured ? "column" : "row")};
    @media (max-width: 768px) {
      padding: 0 0 40px 0;
    }
  }

  .servesNum {
    color: ${(p) => p.theme.DarkBlue};
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    width: 200px;
    border-left: 2px solid ${(p) => p.theme.DarkBlue};
    font-weight: 800;
    height: 60px;
    padding-left: 20px;
    @media (max-width: 768px) {
    }
  }
  .text {
    display: flex;
    justify-content: flex-end;
    font-size: 1.2em;
  }
  .num {
    display: flex;
    font-family: "interstate-compressed";
    font-size: 6em;
    line-height: 48px;
  }

  .carton {
    width: 80px;
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  .img {
    width: 80px;
  }
`

const HeroArea = ({
  title,
  hero_title,
  fields,
  children,
  flavors,
  recipe,
  featured,
}) => {
  const trail = [
    {
      label: "FLAVORS",
      link: "/flavors/",
    },
    {
      label: "RECIPES",
      link: "/recipes/",
    },
    {
      label: !featured ? title : "FEATURED RECIPE",
      link: "",
    },
  ]
  return (
    <HeroAreaStyled
      bgColor={fields.color_2}
      fgColor={fields.color_1}
      featured={featured}
    >
      <div className="info">
        <div className="inner">
          <BreadCrumbs trail={trail} />
          <h1
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: hero_title,
            }}
          />
          <div className="servings">
            {!featured
              ? flavors.map((flavor, i) => {
                  const img =
                    flavor.acf.flavor.carton_image.localFile.childImageSharp
                      .fluid
                  const { promotion } = flavor.acf.flavor
                  const image = img.src
                  const name = flavor.acf.flavor.name
                  const product = {
                    name,
                    image,
                  }
                  return (
                    <Link
                      to={postPath("/flavors/", name)}
                      className="hvr-buzz-out"
                    >
                      <Img fluid={img} className="carton" alt={name} />
                      <PromoSticker
                        promotion={promotion}
                        className="smaller topLeftSmall"
                      />
                    </Link>
                  )
                })
              : ""}
            {!featured ? (
              <div className="servesNum">
                <span className="text">SERVES</span>
                <span className="num">{fields.servings}</span>
              </div>
            ) : (
              ""
            )}
            {featured ? (
              <SVG
                name="SWave"
                className="wave"
                color={recipe.wave_color || "white"}
              />
            ) : (
              ""
            )}
            {children}
          </div>
        </div>
      </div>
      <div className="background">
        {fields.hero_image ? (
          <Img
            alt={title}
            fluid={fields.hero_image.localFile.childImageSharp.fluid}
          />
        ) : (
          ""
        )}
      </div>
    </HeroAreaStyled>
  )
}

export default HeroArea
